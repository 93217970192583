import {reactive, toRefs, computed,defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import PiChangeCardUtil,{IPiChangeCardDataObj} from './piChangeCardUtil';
import Order from '@/views/project/order/utils/order/Order.vue';
export default defineComponent({
    name:'PiChangeCard',
    title:'PI变更维护',
    modelType:'bill',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IPiChangeCardDataObj=reactive<IPiChangeCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                approveLog:{
                    showAttachFile:false,
                    showApprovalMsg:true,
                    showSignInfo:false,
                },
                billModel:'piChange',
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/piChange')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                piId: [utils.UtilPub.commonValidRule(proxy.$t('piChange.form.piId_rule'))],
                jhrq: [utils.UtilPub.commonValidRule(proxy.$t('piChange.form.jhrq_rule'))],
            },
            otherParams:{
                activeName:'baseInfo',
                piData:[],//已发布的PI
                order:{//控制订单哪些属性显示/影藏的对象
                    priceFlag:true,disablePrice:true,sumAmountFlag:true,numEditFlag:false,pccFlag:false,
                    otherInfoFlag:true,disableOtherInfo:true,addBtnFlag:false,disableCustomer:true,customerDelivery:true,
                    disablePcc:true,showHis:false
                },
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PiChangeCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'piId'==params.comboId){
                    return dataObj.otherParams.piData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:any,selectId:string)=>{
            if('piId'==selectId){
                nextTick(()=>{
                    dataObj.otherParams.order.disablePrice=false;
                    if(newValue)dataObj.refMap.get('order').otherParams.orderData=JSON.parse(newValue.item.jsonContent);
                    dataObj.form.jhrq=newValue.item.jhrq;
                    dataObj.form.ysfs=newValue.item.ysfs;
                    dataObj.form.fktk=newValue.item.fktk;
                    dataObj.form.jgtk=newValue.item.jgtk;
                })
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            if('/load'==addOrLoad){
                await nextTick(()=>{
                    dataObj.refMap.get('order').otherParams.orderData=JSON.parse(res.data.jsonContent);
                    dataObj.refMap.get('order').utilInst.initCxCxgghData();
                })
                if('PiChangeList'!=engineInst.engineParams.cardFrom)dataObj.disabled=true;
                dataObj.otherParams.order.disablePrice=dataObj.disabled;
                //记录从哪里进入卡片
                dataObj.otherParams.cardForm=engineInst.engineParams.cardFrom;
                if(res.data && res.data.piId){
                    dataObj.form.piId=dataObj.otherParams.piData.find((item:any)=>item.value==res.data.piId)
                }
            }
        }
        //传入参数到后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }
        //表单保存验证失败之后结果
        const afterErrorValid=(engineInst:any)=>{
            dataObj.otherParams.activeName='baseInfo';
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return dataObj.utilInst.checkSaveData();
        }
        //保存之后
        const afterSaveHandler=async (res:any)=>{
            //如果保存失败，需要把piId给找出来，否则会是一串数字，因为在保存之前已被改变
            dataObj.form.piId=dataObj.otherParams.piData.find((item:any)=>item.value==dataObj.form.piId);
            return true;
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,setParam,afterErrorValid,beforeSaveHandler,afterSaveHandler
        }
    },
    components: {
        Order
    }
});